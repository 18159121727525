import React from "react";
import "./PhotoGallery.css";
import photoGalleryOne from "../../../../accets/photoGallery/galleryPhotoOne.jpg";
import photoGalleryTwo from "../../../../accets/photoGallery/galleryPhotoTwo.jpg";
import photoGalleryThree from "../../../../accets/photoGallery/galleryPhotoThree.jpg";
import photoGalleryFour from "../../../../accets/photoGallery/galleryPhotoFour.jpg";
import photoGalleryFive from "../../../../accets/photoGallery/galleryPhotoFive.jpg";
import photoGallerySix from "../../../../accets/photoGallery/galleryPhotoSix.jpg";
import photoGallerySaven from "../../../../accets/photoGallery/galleryPhotoSaven.jpg";
import photoGalleryEight from "../../../../accets/photoGallery/galleryPhotoEight.jpg";
import photoGalleryNine from "../../../../accets/photoGallery/galleryPhotoNine.jpg";
import photoGalleryTen from "../../../../accets/photoGallery/galleryPhotoTen.jpg";
import photoGalleryEleven from "../../../../accets/photoGallery/galleryPhotoEleven.JPG";
import photoGalleryTwelve from "../../../../accets/photoGallery/galleryPhotoTwelve.jpg";
import photoGalleryThirten from "../../../../accets/photoGallery/galleryPhotoThirten.jpg";
import photoGalleryForteen from "../../../../accets/photoGallery/photoGalleryForteen.jpg";
import photoGalleryFifteen from "../../../../accets/photoGallery/photoGalleryFifteen.jpg";
import photoGallerySixteen from "../../../../accets/photoGallery/photoGallerySixteen.jpg";
import photoGallerySaventeen from "../../../../accets/photoGallery/photoGallerySaventeen.jpg";
import photoGalleryEightten from "../../../../accets/photoGallery/photoGalleryEightten.jpg";
import photoGalleryNinteen from "../../../../accets/photoGallery/photoGalleryNinteen.jpg";
import photoGalleryTwentee from "../../../../accets/photoGallery/photoGalleryTwentee.jpg";

const PhotoGallery = () => {
  // For Safari
  document.body.scrollTop = 0;
  // For Chrome, Firefox, IE and Opera
  document.documentElement.scrollTop = 0;
  return (
    <div className="photoGallery_parent_div">
      <div className="photoGallery_title_div">
        <h1 className="photoGallery_title">Photo Gallery</h1>
      </div>

      <div className="photoGallery_grid_div">
        <div>
          <img src={photoGalleryOne} alt="" />
        </div>
        <div>
          <img src={photoGalleryTwo} alt="" />
        </div>
        <div>
          <img src={photoGalleryThree} alt="" />
        </div>
        <div>
          <img src={photoGalleryFour} alt="" />
        </div>
        <div>
          <img src={photoGalleryFive} alt="" />
        </div>
        <div>
          <img src={photoGallerySix} alt="" />
        </div>
        <div>
          <img src={photoGallerySaven} alt="" />
        </div>
        <div>
          <img src={photoGalleryEight} alt="" />
        </div>
        <div>
          <img src={photoGalleryNine} alt="" />
        </div>
        <div>
          <img src={photoGalleryTen} alt="" />
        </div>
        <div>
          <img src={photoGalleryEleven} alt="" />
        </div>
        <div>
          <img src={photoGalleryTwelve} alt="" />
        </div>
        <div>
          <img src={photoGalleryThirten} alt="" />
        </div>
        <div>
          <img src={photoGalleryForteen} alt="" />
        </div>
        <div>
          <img src={photoGalleryFifteen} alt="" />
        </div>
        <div>
          <img src={photoGallerySixteen} alt="" />
        </div>
        <div>
          <img src={photoGallerySaventeen} alt="" />
        </div>
        <div>
          <img src={photoGalleryEightten} alt="" />
        </div>
        <div>
          <img src={photoGalleryNinteen} alt="" />
        </div>
        <div>
          <img src={photoGalleryTwentee} alt="" />
        </div>
      </div>
    </div>
  );
};

export default PhotoGallery;
