import React from "react";
import "./Home.css";

import AboutNeurosurgeonDrAtiqulIslam from "../AboutNeurosurgeonDrAtiqulIslam/AboutNeurosurgeonDrAtiqulIslam";
import HomeVideoGallery from "../HomeVideoGallery/HomeVideoGallery";
import Banner from "../Banner/Banner";
import WhatWeTreat from "../WhatWeTreat/WhatWeTreat";
import MeetOurNeurosurgons from "../MeetOurNeurosurgons/MeetOurNeurosurgons";
import OnlineAppointment from "../OnlineAppointment/OnlineAppointment";
import NeurosurgeryLocations from "../NeurosurgeryLocations/NeurosurgeryLocations";
import PatientsAfterOperation from "./../PatientsAfterOperation/PatientsAfterOperation";
import HomeGallery from "./../HomeGallery/HomeGallery";
import PatientNeeds from "./../PatientNeeds/PatientNeeds";
const Home = () => {
  // For Safari
  document.body.scrollTop = 0;
  // For Chrome, Firefox, IE and Opera
  document.documentElement.scrollTop = 0;
  return (
    <div id="home_parent_div" className="home_parent_div">
      <Banner></Banner>
      <div id="about-section">
        <AboutNeurosurgeonDrAtiqulIslam></AboutNeurosurgeonDrAtiqulIslam>
      </div>
      <WhatWeTreat></WhatWeTreat>
      <MeetOurNeurosurgons></MeetOurNeurosurgons>
      <OnlineAppointment></OnlineAppointment>
      <HomeVideoGallery></HomeVideoGallery>
      <NeurosurgeryLocations></NeurosurgeryLocations>
      {/* ====================================================== */}
    </div>
  );
};

export default Home;
