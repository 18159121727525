import React, { useState } from "react";
import "./NeurosurgeryLocations.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocation, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const NeurosurgeryLocations = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const viewDetailFunction = () => {
    setIsModalOpen(true);
    document.body.classList.add("modal-open");
  };

  const closeModalFunction = () => {
    setIsModalOpen(false);
    document.body.classList.remove("modal-open");
  };

  return (
    <div className="neurosurgery_location_parent_div">
      <h1>Locations</h1>
      <div className="neurosurgery_cart_div">
        <div>
          <FontAwesomeIcon icon={faLocation} />
          <p>Hospital Location:</p>
          <p>National Institute of Neuroscience & Hospital Agargaon, Dhaka</p>
          <p>01915-449-083 / 02-222225937</p>
          <Link>
            <button onClick={viewDetailFunction} className="view_details">
              View Map
            </button>
          </Link>
        </div>
        <div>
          <FontAwesomeIcon icon={faLocation} />
          <p>Chamber: </p>
          <p>
            Islami Bank Central Hospital 30 Anjuman Mofidul Islam Road, Kakrail,
            Dhaka-1000
          </p>
          {/* <p>01810000116 / 02-222225937</p> */}
          <p>01810-000-116 / 02-222225801-2</p>
          <Link>
            <button onClick={viewDetailFunction} className="view_details">
              View Map
            </button>
          </Link>
        </div>
      </div>
      {/* MODAL START */}
      {/* MODAL AND OVERLAY */}
      <div className={`overlay ${isModalOpen ? "show" : ""}`}></div>
      {isModalOpen && (
        <div
          id="Online_appointment_time_div"
          className="Online_appointment_time_div"
        >
          <div className="xmark_div">
            <h3></h3>
            <FontAwesomeIcon
              onClick={closeModalFunction}
              className="faXmark"
              icon={faXmark}
            />
          </div>

          <iframe
            className="neurosurgery_map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3652.2749853445735!2d90.40749491449282!3d23.737571495184277!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755b8f4de15d5eb%3A0x35df10409c6ffeef!2sIslami%20Bank%20Central%20Hospital%2C%20Kakrail!5e0!3m2!1sen!2sbd!4v1655841357410!5m2!1sen!2sbd"
            title="Neurosurgeon dr. atique"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      )}
    </div>
  );
};

export default NeurosurgeryLocations;
