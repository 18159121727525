import React from "react";
import "./WhatWeTreat.css";
import logoOne from "../../accets/WhatWeTreat/logoOne.png";
import logoTwo from "../../accets/WhatWeTreat/logoTwo.png";

import BrainTumour from "../../accets/WhatWeTreat/Brain Tumour.webp";
import EndoscopicBrainSurgery from "../../accets/WhatWeTreat/Endoscopic Brain Surgery.png";
import EpilepsySurgery from "../../accets/WhatWeTreat/Epilepsy Surgery.png";
import Microdiscectomy from "../../accets/WhatWeTreat/Microdiscectomy.png";
import PediatricNeuroSurgery from "../../accets/WhatWeTreat/Pediatric Neuro Surgery.png";
import SkullBaseSurgery from "../../accets/WhatWeTreat/Skull Base Surgery.png";
import SpinalTumour from "../../accets/WhatWeTreat/Spinal Tumour.png";
import StrokeSurgery from "../../accets/WhatWeTreat/Stroke Surgery.png";
import TraumaticBraiin from "../../accets/WhatWeTreat/Traumatic Braiin.png";
import VascularNeuroSurgery from "../../accets/WhatWeTreat/Vascular Neuro Surgery.png";
import { Link } from "react-router-dom";
const WhatWeTreat = () => {
  return (
    <div className="what_we_treat_parent_div">
      {/* <h1 className="what_we_treat_title">Neurosurgery What We Treat</h1> */}
      <h2 className="what_we_treat_title">Treatment & Surgery</h2>

      <div className="whatWeTreat_grid_div">
        <div className="whatWeTreat_single_cart">
          <h3 className="whatWeTreat_cart_title">Spinal Surgery</h3>
          <div className="what_we_treat_blank_div"></div>
          <div className="what_we_treat_text_div">
            <img className="whatWeTreat_logo" src={logoOne} alt="" />
            <div>
              <p>
                The spine is a critical structure in the human body, serving as
                a support system for the upper body, protecting the spinal cord,
                and facilitating movement...
              </p>

              <Link to="/spinalSurgery">
                <button className="readMore_btn">Read More</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="whatWeTreat_single_cart">
          <h3 className="whatWeTreat_cart_title">Brain Tumour Operation</h3>
          <div className="what_we_treat_blank_div"></div>
          <div className="what_we_treat_text_div">
            <img className="whatWeTreat_logo" src={logoTwo} alt="" />
            <div>
              <p>
                A brain tumor diagnosis can be overwhelming, but with
                advancements in neurosurgery, effective treatment options are
                available to provide relief and improve...
              </p>
              <Link to="/brainTumourOperation">
                <button className="readMore_btn">Read More</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="whatWeTreat_single_cart">
          <h3 className="whatWeTreat_cart_title">Endoscopic Brain Surgery</h3>
          <div className="what_we_treat_blank_div"></div>
          <div className="what_we_treat_text_div">
            <img
              className="whatWeTreat_logo"
              src={EndoscopicBrainSurgery}
              alt=""
            />
            <div>
              <p>
                Advancements in medical technology have transformed the field of
                neurosurgery, making procedures less invasive and recovery
                faster. Endoscopic brain...
              </p>
              <Link to="/endoscopicBrainSurgery">
                <button className="readMore_btn">Read More</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="whatWeTreat_single_cart">
          <h3 className="whatWeTreat_cart_title">Skull Base Surgery</h3>
          <div className="what_we_treat_blank_div"></div>
          <div className="what_we_treat_text_div">
            <img className="whatWeTreat_logo" src={SkullBaseSurgery} alt="" />
            <div>
              <p>
                The base of the skull is a critical area that houses vital
                nerves, blood vessels, and structures connecting the brain to
                the rest of the body. Conditions...
              </p>
              <Link to="/skullBaseSurgery">
                <button className="readMore_btn">Read More</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="whatWeTreat_single_cart">
          <h3 className="whatWeTreat_cart_title">Vascular Neuro Surgery</h3>
          <div className="what_we_treat_blank_div"></div>
          <div className="what_we_treat_text_div">
            <img
              className="whatWeTreat_logo"
              src={VascularNeuroSurgery}
              alt=""
            />
            <div>
              <p>
                Vascular neurosurgery is a highly specialized branch of
                neurosurgery focused on diagnosing and treating conditions
                involving the blood vessels...
              </p>
              <Link to="/vascularNeuroSurgery">
                <button className="readMore_btn">Read More</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="whatWeTreat_single_cart">
          <h3 className="whatWeTreat_cart_title">Pediatric Neuro Surgery</h3>
          <div className="what_we_treat_blank_div"></div>
          <div className="what_we_treat_text_div">
            <img
              className="whatWeTreat_logo"
              src={PediatricNeuroSurgery}
              alt=""
            />
            <div>
              <p>
                Children with neurological conditions require specialized care
                that addresses their unique needs with compassion and precision.
                Pediatric neurosurgery...
              </p>
              <Link to="/pediatricNeuroSurgery">
                <button className="readMore_btn">Read More</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="whatWeTreat_single_cart">
          <h3 className="whatWeTreat_cart_title">
            Traumatic Braiin & Spine Surgery
          </h3>
          <div className="what_we_treat_blank_div"></div>
          <div className="what_we_treat_text_div">
            <img className="whatWeTreat_logo" src={TraumaticBraiin} alt="" />
            <div>
              <p>
                Traumatic brain and spine injuries are life-altering conditions
                that demand immediate and expert medical intervention. Whether
                caused by accidents...
              </p>
              <Link to="/traumaticBrainAndSpineSurgery">
                <button className="readMore_btn">Read More</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="whatWeTreat_single_cart">
          <h3 className="whatWeTreat_cart_title">Epilepsy Surgery</h3>
          <div className="what_we_treat_blank_div"></div>
          <div className="what_we_treat_text_div">
            <img className="whatWeTreat_logo" src={EpilepsySurgery} alt="" />
            <div>
              <p>
                Epilepsy surgery is a transformative treatment option for
                individuals whose seizures cannot be controlled with medication
                alone. It offers the possibility...
              </p>
              <Link to="/epilepsySurgery">
                <button className="readMore_btn">Read More</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="whatWeTreat_single_cart">
          <h3 className="whatWeTreat_cart_title">Spinal Tumour</h3>
          <div className="what_we_treat_blank_div"></div>
          <div className="what_we_treat_text_div">
            <img className="whatWeTreat_logo" src={SpinalTumour} alt="" />
            <div>
              <p>
                Spinal tumors are abnormal growths that develop in or around the
                spinal cord or vertebrae. They can be either benign
                (non-cancerous)...
              </p>
              <Link to="/spinalTumour">
                <button className="readMore_btn">Read More</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="whatWeTreat_single_cart">
          <h3 className="whatWeTreat_cart_title">Microdiscectomy</h3>
          <div className="what_we_treat_blank_div"></div>
          <div className="what_we_treat_text_div">
            <img className="whatWeTreat_logo" src={Microdiscectomy} alt="" />
            <div>
              <p>
                Microdiscectomy is a minimally invasive surgical procedure
                designed to relieve nerve compression caused by herniated discs
                in the spine. This advanced...
              </p>
              <Link to="/microdiscectomy">
                <button className="readMore_btn">Read More</button>
              </Link>
            </div>
          </div>
        </div>
        <div className="whatWeTreat_single_cart">
          <h3 className="whatWeTreat_cart_title">Stroke Surgery</h3>
          <div className="what_we_treat_blank_div"></div>
          <div className="what_we_treat_text_div">
            <img className="whatWeTreat_logo" src={StrokeSurgery} alt="" />
            <div>
              <p>
                Stroke is a serious medical condition that occurs when the blood
                supply to part of the brain is interrupted, either due to a
                blockage (ischemic stroke)...
              </p>
              <Link to="/strokeSurgery">
                <button className="readMore_btn">Read More</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhatWeTreat;
